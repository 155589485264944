require('./bootstrap');
require('admin-lte');
require('icheck');
require('daterangepicker');
const moment=require('moment');
window.toastr = require('toastr');
const Swal = require('sweetalert2');
const fancybox = require('@fancyapps/fancybox');

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

var locale={
    'format': 'DD/MM/YYYY',
    "separator": " / ",
    "applyLabel": "Aplicar",
    "cancelLabel": "Cancelar",
    "fromLabel": "DE",
    "toLabel": "HASTA",
    "customRangeLabel": "Custom",
    "daysOfWeek": [
        "Dom",
        "Lun",
        "Mar",
        "Mie",
        "Jue",
        "Vie",
        "Sáb"
    ],
    "monthNames": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ],
    "firstDay": 1
}

function SwalConfim(callBack){
    if(typeof callBack !== 'function'){
        console.error({
            'type':'SwalConfirm',
            'Description':"A valid function has not been declared for the swal callback"
        });
        return false;
    }
    Swal.fire({
        title: '¿Está seguro?',
        html: "<small>Se requiere de su confirmación para seguir con esta operación.</small>",
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, adelante',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        (callBack)(result.value?true:false);
    });
}

function ToastrOk(message){
    toastr['success'](message, '¡Exelecte!');
}

$(function(){
    $('input[type=checkbox]').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue',
        increaseArea: '20%' // optional
    });
    if($('#toastr').length>0){
        var type=$('#toastr').data('type');
        var title=$('#toastr').data('title');
        var message=$('#toastr').data('message');
        toastr[type](message, title);
    }

    $(".delete").click(function(){
        var url=$(this).attr('href');
        console.log(url);
        SwalConfim(function(confirm){
            if(confirm){
                axios.delete(url).then(res => {
                    if(res.status===200 && res.data.destroy){
                        ToastrOk(res.data.name+' eliminado con éxito.');
                        $("#row-"+res.data.id).remove();
                    }
                });
            }
        });
        return false;
    });

    $('.date-single').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        startDate: moment,
        locale: locale
    });

    $('[data-fancybox]').fancybox({
        toolbar  : false,
        smallBtn : true,
        iframe : {
            preload : false
        }
    })
});